import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import ExtraCard from '../components/elements/ExtraCard'
import RichText from '../rich-text/RichText';


const PageTemplate = (props) => {
    const { nodes } = props.data.allContentfulPage
    const { body, extra, title, seoDescription, seoImage } = nodes[0]
    return (
        <Layout 
            path={props.path} 
            title={title} 
            description={seoDescription && seoDescription.text} 
            image={seoImage.file.url}
        >
            <article className="page max-w-3xl">
                { body && body.json &&  <RichText text={body.json} />} 
                {extra ?
                    <div 
                    className="grid grid-cols-2 xs:grid-cols-3 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:row-gap-6 lg:col-gap-6 px-2">
                        {extra.map(({ description, linkTo, image, faIcon, id }) => {
                            return <ExtraCard 
                                        description={description} 
                                        linkTo={linkTo} 
                                        image={image} 
                                        faIcon={faIcon} 
                                        key={id}
                                    />
                        })}
                    </div>
                    : ""}
            </article>
        </Layout>
    )
}


export const PageQuery = graphql`
    query Pages($id: String!) {
            allContentfulPage(filter: {id: {eq: $id}}) {
            nodes {
                title
                slug
                body {
                    json
                }
                seoDescription {
                    text: seoDescription
                }
                seoImage {
                    file {
                        url
                    }
                    title
                }
                extra {
                    description
                    faIcon
                    image {
                        fluid(maxWidth: 400, quality: 100) {
                            srcSet
                            src
                            base64
                            sizes
                        }
                        title
                    }
                    id
                    linkTo
                    }
                }
            }
        }
`

PageTemplate.propTypes = {
    data: PropTypes.object
}

export default PageTemplate
