import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import LinkTo from '../LinkTo'

const ExtraCard = ({ description, faIcon, linkTo, image }) => {
    return (
        <LinkTo linkTo={linkTo} className="max-w-sm relative h-full rounded-lg bg-gray-800 round-lg">
            {image && image.fluid ? <Img className=" h-64 top-0 z-0  rounded-lg opacity-50" alt={image.title} fluid={image.fluid} /> : ""}
            <div className="absolute top-0 z-10 w-full text-gray-200 shadow-subtle rounded-lg hover:shadow-2xl transition-shadow duration-500 text-center flex h-full flex-col justify-center lg:px-4 px-2">
                {faIcon && <i className={`${faIcon} text-4xl mb-2`} />}
                {description && <h3 className="text-xl font-body text-white mb-0 ">{description}</h3>}
            </div>

        </LinkTo>
    )
}

ExtraCard.propTypes = {
    description: PropTypes.string,
    faIcon: PropTypes.string,
    linkTo: PropTypes.string.isRequired,
    image: PropTypes.object,
}

export default ExtraCard
